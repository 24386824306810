import React, { useState, useEffect } from "react";
import * as Queries from "./Queries";
import { gql, useQuery } from "@apollo/client";
import _ from "lodash";
import MaskedInput from "antd-mask-input";
import {
	Modal,
	Button,
	Col,
	Row,
	Layout,
	Form,
	Input,
	Select,
	Alert,
	DatePicker,
	Upload,
	notification,
} from "antd";
import InputNumber from "components/InputNumber";
import { useMutation, useLazyQuery } from "@apollo/client";
import Swal from "sweetalert2";
import debounce from "functions/debounce";

import { UploadOutlined } from "@ant-design/icons";
import { useImmer } from "use-immer";

function ModalView({ open, refetch, close }) {

	const [state, setState] = useImmer({});

	const [saveAction, { loading: loadingSave }] = useMutation(gql`
		mutation DASRequest($input: RequestDASInput!) {
			DASRequest(input: $input)
		}
	`);



	const save = async () => {

		if(!state.cnpj) {
			return Modal.error({
				content: 'Por favor, informe um CNPJ válido'
			})
		}

		if(state.cnpj.replace(/\D/g,'').length < 14) {
			return Modal.error({
				content: 'Por favor, informe um CNPJ válido'
			})
		}

		if(!state.referenceDate) {
			return Modal.error({
				content: 'Por favor, informe um período válido'
			})
		}

		const response = await saveAction({
			variables: {
				input: {
					...state,
				},
			},
		});

		if(response?.data?.DASRequest) {
			close();
			notification.success({
				message: "DAS solicitada com sucesso."
			})
		} else {
			return Modal.error({
				content: 'Não foi possível solicitar a DAS.'
			})
		}

	};


	const onClose = () => {
		setState(null);
		close();
	};

	return (
		<Modal
			title="Solicitar DAS"
			visible={open}
			onOk={save}
			onCancel={onClose}
			footer={[
				<Button key="back" onClick={onClose} disabled={loadingSave}>
					Cancelar
				</Button>,
				<Button
					type="primary"
					loading={loadingSave}
					disabled={loadingSave}
					onClick={save}
				>
					Enviar
				</Button>,
			]}
		>
			<Form layout="vertical">
				<Form.Item label="CNPJ" style={{ marginBottom: "15px" }}>
					<MaskedInput
						mask={"11.111.111/1111-11"}
						placeholderChar={""}
						isRevealingMask
						placeholder={"Digite o CNPJ"}
						onChange={(e) => {
							setState(d => {
								d.cnpj = e.target.value;
							})
						}}
					/>
				</Form.Item>
				<Form.Item label="Mês Referência" style={{ marginBottom: "5px" }}>
					<DatePicker
						picker="month"
						style={{
							width: "100%",
						}}
						format={'MM/YYYY'}
						onChange={(e) => {
							const period = e.format("MM/YYYY");
							setState(d => {
								d.referenceDate = period;
							})
						}}
					/>
				</Form.Item>
			</Form>
		</Modal>
	);
}

export default ModalView;
