import { gql } from "@apollo/client";

export const TICKETS = gql`
  query PartnerTickets($input: PartnerTicketFilter, $page: Int!) {
    PartnerTickets(input: $input, page: $page) {
      tickets {
        _id
        category
        description
        resolution_date
        status
        partner {
          _id
          name
        }
        admin_id
        createdAt
        observation
        unread
      }
      currentPage
      total
      pages
    }
  }
`;

export const TICKET_SAVE = gql`
mutation PartnerTicket($id: String!, $admin: String, $status: String) {
   PartnerTicket(id:$id, admin: $admin, status: $status) {
    _id
  }
}
`;

export const TICKET_OBSERVATION = gql`
  mutation PartnerTicketObservation($id: String!, $observation: String) {
    PartnerTicketObservation(id: $id, observation: $observation) {
      _id
    }
  }
`;

export const TICKET_REMOVE = gql`
  mutation PartnerTicketRemove($id: String!) {
    PartnerTicketRemove(id: $id)
  }
`;

export const ADMINS = gql`
  query Admins($status: Int) {
  Admins(status: $status) {
    _id
    name
  }
}
`;
