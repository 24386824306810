import { useEffect } from "react";
import {
	Modal,
	Button,
	Form,
	Input,
	Select, Descriptions,
	Badge
} from "antd";
import "antd/es/slider/style";
import { statuses } from "./Misc";
import { categories } from "./Misc";
import { gql, useLazyQuery } from "@apollo/client";

const { Option } = Select;
const { TextArea } = Input;

function ModalView({ visible, refetch, close, data, setModal }) {

	const [loadData, { loading, error, data: queryData }] = useLazyQuery(gql`
		query PartnerTicket($id: String!) {
			PartnerTicket(id: $id) {
				category
				description
				resolution_date
				fileUrl
				status
			}
		}
	`);

	useEffect(() => {
		if (visible && data.ticketId) {
			loadData({
				variables: {
					id: data.ticketId,
				},
			});
		}
	}, [visible]);

	
	const badgeColors = {
		0: "#f2423a",
		1: "#1494fc",
		2: "#fe961e",
		3: "#50ad54",
		4: "#9e9e9e",
	};


	return (
		<Modal
			title="Sobre o Ticket de Suporte"
			visible={visible}
			onOk={() => null}
			onCancel={() => {
				setModal(false)
			}}
			footer={null}
		>
			<Form layout="vertical">
				<div>
					{loading && (
						<div>Carregando</div>
					)}
					{!loading && queryData && (
						<div>
							<Descriptions title="User Info" bordered layout="vertical">
								<Descriptions.Item label="Categoria" span={3}>
									{queryData?.PartnerTicket?.category &&
										categories.find(
											(i) => i.value === queryData?.PartnerTicket?.category
										).label}
								</Descriptions.Item>
								<Descriptions.Item label="Descrição" span={3}>
									{queryData?.PartnerTicket?.description}
								</Descriptions.Item>
								{queryData?.PartnerTicket?.fileUrl && (
									<Descriptions.Item label="Arquivo">
										<Button>Ver arquivo</Button>
									</Descriptions.Item>
								)}
								<Descriptions.Item label="Status">
									<Badge
										color={badgeColors[queryData?.PartnerTicket?.status]}
										style={{ marginRight: 10 }}
										text={statuses[queryData?.PartnerTicket?.status]}
									/>
								</Descriptions.Item>
							</Descriptions>
						</div>
					)}
				</div>
			</Form>
		</Modal>
	);
}

export default ModalView;
