const statuses = {
	0: 'Aguardando', 
	1: 'Em andamento', 
	2: 'Aguardando cliente',
	3: 'Resolvido',
	4: 'Cancelado',
};


const categories = [
	{
	  value: "1",
	  label: "Login/Senha",
	},
	{
	  value: "2",
	  label: "DAS",
	},
	{
	  value: "3",
	  label: "Notas Fiscais",
	},
	{
	  value: "4",
	  label: "Tutoriais",
	},
			  {
	  value: "5",
	  label: "Planos",
	},
			  {
	  value: "6",
	  label: "Site",
	},
			  {
	  value: "7",
	  label: "CRM",
	},
			  {
	  value: "8",
	  label: "Outros",
	},
 ]

//  enum status: { waiting: 0, in_progress: 1, waiting_customer: 2, solved: 3, cancelled: 4 }

export {
	statuses,
	categories
}