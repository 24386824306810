import { Layout, Typography, Button, Table, Badge, Tag, Modal } from "antd";

import dayjs from "dayjs";
import { EditOutlined } from "@ant-design/icons";
import { useImmer } from "use-immer";
import Tutorial from "./Modal";
import { gql, useQuery } from "@apollo/client";
import { Link } from "react-router-dom";

import Loading from "components/Loading/Page";
import Error from "components/Error/Page";
import PartnerSearch from "components/PartnerSearch";
import { useEffect } from "react";

const { Content } = Layout;
const { Title } = Typography;

function VideoView({ item, open, close }) {
	function youtube_parser(url) {
		var regExp =
			/^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
		var match = url.match(regExp);
		return match && match[7].length == 11 ? match[7] : false;
	}

	function vimeo_parser(url) {
		var regex =
			/https?:\/\/(?:www\.)?vimeo\.com\/(?:channels\/(?:\w+\/)?|groups\/[^\/]*\/videos\/|album\/\d+\/video\/|)(\d+)(?:$|\/|\?)/;
		var match = url.match(regex);
		if (match) {
			return match[1];
		} else {
			return null;
		}
	}

	return (
		<Modal
			visible={open}
			onCancel={() => {
				close();
			}}
			width={900}
			footer={false}
		>
			{item && (
				<div style={{marginTop: 20}}>
					{item.type === "1" ? (
						<>
							<div className="video-view">
								<iframe
									title="video1"
									width="100%"
									height="100%"
									src={`https://www.youtube.com/embed/${youtube_parser(
										item.url
									)}`}
								></iframe>
							</div>
						</>
					) : null}

					{item.type === "4" ? (
						<>
							<div className="video-view">
								<iframe
									title="video2"
									src={`https://player.vimeo.com/video/${vimeo_parser(
										item.url
									)}`}
									frameborder="0"
									allow="autoplay; fullscreen; picture-in-picture"
									allowfullscreen
									width="100%"
									height="100%"
								></iframe>
							</div>
						</>
					) : null}

					{item.type === "2" ? (
						<>
							<div className="video-view">
								<video width="100%" height="100%" controls>
									<source src={item.url} type="video/mp4" />
								</video>
							</div>
						</>
					) : null}
				</div>
			)}
		</Modal>
	);
}

function Tutorials() {
	const [modal, setModal] = useImmer({
		visible: false,
	});

	const [view, setView] = useImmer({
		visible: false,
	});

	const [filters, setFilters] = useImmer({});

	const { data, loading, refetch, error } = useQuery(gql`
		{
			Tutorials {
				_id
				title
				description
				type
				source
				order
				url
				status
				partner_id
				partner_name
			}
		}
	`);

	const filteredData = (data) => {
		if (Object.keys(filters).length) {
			return data.filter((i) => {
				if (filters.partner_id && i.partner_id !== filters.partner_id) {
					return false;
				}
				return i;
			});
		}
		return data;
	};

	return (
		<Layout className={"page-wrapper"}>
			<Content className="site-layout-background">
				<div className="page-title">
					<Title>Tutoriais</Title>
					<div>
						<Button
							type="primary"
							onClick={() => {
								setModal({ visible: true });
							}}
						>
							Novo
						</Button>
					</div>
				</div>

				<VideoView
					{...view}
					close={() => {
						setView({
							open: false,
						});
					}}
				/>

				<div
					style={{
						display: "flex",
						marginBottom: 20,
						gap: 20,
					}}
				>
					<div
						style={{
							flex: 1,
						}}
					>
						<PartnerSearch
							additionalProps={{
								style: {
									width: "100%",
								},
								allowClear: true,
							}}
							onChange={(e) => {
								setFilters((d) => {
									d.partner_id = e;
								});
							}}
						/>
					</div>
				</div>

				<div className="dashboard">
					<div>
						{loading ? <Loading /> : null}
						{!loading && error ? <Error refetch={() => refetch()} /> : null}
						{!loading && !error && (
							<Table
								rowKey="_id"
								dataSource={filteredData(data.Tutorials)}
								pagination={{
									hideOnSinglePage: true,
								}}
								columns={[
									{
										key: "title",
										dataIndex: "title",
										title: "Titulo",
									},
									{
										key: "type",
										dataIndex: "type",
										title: "Tipo",
										render: (value) => {
											if (value === "1") return "Youtube";
											if (value === "2") return "Arquivo Video";
											if (value === "3") return "Arquivo PDF";
											if (value === "4") return "Vimeo";
										},
									},
									{
										key: "order",
										dataIndex: "order",
										title: "Ordem",
										render: (value) => value || "-",
									},
									{
										key: "source",
										dataIndex: "source",
										title: "Fonte",
										render: (value) => {
											if (value === "admin") return <Tag>Admin</Tag>;
											if (value === "partner") return <Tag>Parceiro</Tag>;
										},
									},
									{
										key: "name",
										dataIndex: "name",
										title: "Parceiro",
										render: (value, row) => {
											if (row.partner_name) {
												return (
													<Link to={`partner/${row.partner_id}`}>
														{row.partner_name}
													</Link>
												);
											}
											return "-";
										},
									},
									{
										key: "url",
										dataIndex: "url",
										title: "URL",
										render: (value, row) => {
											if (!value) return "-";

											if (["2", "4", "1"].includes(row.type)) {
												return (
													<Button
														onClick={() => {
															setView({
																open: true,
																item: row,
															});
														}}
													>
														Ver
													</Button>
												);
											}

											return (
												<Button
													onClick={() => {
														window.open(value);
													}}
												>
													Abrir
												</Button>
											);
										},
									},
									{
										key: "status",
										dataIndex: "status",
										title: "Status",
										render: (value) => {
											if (value === "1")
												return <Tag color="orange">Inativo</Tag>;
											if (value === "2") return <Tag color="green">Ativo</Tag>;
											if (value === "3") return <Tag>Rascunho</Tag>;
										},
									},
									{
										key: "action",
										dataIndex: "action",
										title: "",
										width: 100,
										render: (value, row) => {
											const { _id, ...data } = row;

											return (
												<Button
													onClick={() => {
														setModal({
															visible: true,
															id: _id ?? null,
															data: data,
														});
													}}
												>
													Editar <EditOutlined />
												</Button>
											);
										},
									},
								]}
							/>
						)}
					</div>

					<Tutorial
						{...modal}
						refetch={refetch}
						close={() => {
							setModal({ visible: false });
						}}
					/>
				</div>
			</Content>
		</Layout>
	);
}

export default Tutorials;
